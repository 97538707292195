<script>
import { language } from '../helper/lang'
export default {
  name: 'BrainFormFour',
  data () {
    return {
      lang: language,
      clinicalStage: {
        value: '',
        isError: false,
        message: ''
      },
    }
  },
  updated() {
    this.clinicalStage.message = this.lang[this.$store.getters.language].clinical.questionFive.error;
  },
  mounted() {
    let data = this.$store.getters.data;
    if (data.clinicalStage) {
      this.clinicalStage.value = data.clinicalStage;
    }
  },
  methods: {
    prevComponent() {
      this.$store.dispatch('componentFormThree', true);
      this.$store.dispatch('componentFormFour', false);
    },
    async nextComponent() {

      let invalid = false;

      if (this.clinicalStage.value == '') {
        this.clinicalStage.isError = true;
        invalid = true;
      }

      if (!invalid) {

        let data = await this.$store.getters.data,
            dataCalculated = this.$store.getters.dataCalculated;

        data.clinicalStage = this.clinicalStage.value; 
        dataCalculated.clinicalStage = 0; 
        if (this.clinicalStage.value == 'stageTwo') {
          dataCalculated.clinicalStage = -0.812;
        } else if (this.clinicalStage.value == 'stageThree') {
          dataCalculated.clinicalStage = -0.427;
        } else if (this.clinicalStage.value == 'stageFour') { 
          dataCalculated.clinicalStage = 0.467;
        }
        
        await this.$store.dispatch('data', data);
        await this.$store.dispatch('dataCalculated', dataCalculated);
        await this.$store.dispatch('componentFormFive', true);
        await this.$store.dispatch('componentFormFour', false);
      }
    }
  }
}

</script>

<template>
  <img src="../assets/img/Form.png" alt="Structure Brain" class="banner-form">
  <img src="../assets/img/logo.png" alt="Logo Brain" class="logo-form">
  <div class="box-container-form">
    <div class="content-title text-lg-start text-md-start text-center">
      <div class="title">{{ lang[$store.getters.language].clinical.title }}</div>
    </div>
    <div class="wrapper-form">
      <div class="form-group">
        <label class="col-form-label-lg">
          5.- {{ lang[$store.getters.language].clinical.questionFive.text }}
        </label>
        <div class="row d-flex align-items-center">
          <div class="col-md-5">
            <div class="custom-radio" :class="{ 'is-invalid' : clinicalStage.isError }">
              <input type="radio" id="option6" name="clinicalStage" value="stageOne" v-model="clinicalStage.value">
              <label for="option6">{{ lang[$store.getters.language].clinical.questionFive.optionOne }}</label>
            </div>
            <div class="custom-radio">
              <input type="radio" id="option7" name="clinicalStage" value="stageTwo" v-model="clinicalStage.value">
              <label for="option7">{{ lang[$store.getters.language].clinical.questionFive.optionTwo }}</label>
            </div>
            <div class="custom-radio">
              <input type="radio" id="option8" name="clinicalStage" value="stageThree" v-model="clinicalStage.value">
              <label for="option8">{{ lang[$store.getters.language].clinical.questionFive.optionThree }}</label>
            </div>
            <div class="custom-radio">
              <input type="radio" id="option9" name="clinicalStage" value="stageFour" v-model="clinicalStage.value">
              <label for="option9">{{ lang[$store.getters.language].clinical.questionFive.optionFour }}</label>
            </div>
            <div v-if="clinicalStage.isError" class="invalid-feedback">
              {{ clinicalStage.message }}
            </div>
          </div>

          <div class="col-md-7">
            <img v-if="$store.getters.language == 'es'" src="../assets/img/Tabla-es.png" alt="Logo Brain" class="img-table img-fluid">
            <img v-if="$store.getters.language == 'en'" src="../assets/img/Tabla-en.png" alt="Logo Brain" class="img-table img-fluid">
          </div>
        </div>
      </div>
    </div>
    <div class="form-group text-md-start text-lg-start text-center">
      <button @click="prevComponent" class="btn-brain btn-brain-blue-prev">{{ lang[$store.getters.language].buttons.back }}</button>
      <button @click="nextComponent" class="btn-brain mx-auto btn-brain-blue-next">{{ lang[$store.getters.language].buttons.next }}</button>
    </div>
  </div>
</template>

<style scoped>
  .custom-radio label {
    min-width: 35vh
  }
  img.img-table {
    position: relative;
    z-index: 1;
  }
</style>
