 <script>
    import { language } from '../helper/lang'
    export default {
        name: 'BrainIntro',
        data() {
            return {
                lang: language
            }
        },
        methods: {
        nextComponent() {
            this.$store.dispatch('componentIntroduction', false);
            this.$store.dispatch('componentFormOne', true);
        },
      }
    }
</script>

<template>
    <div class="box-container">
        <div class="box-container-info-box">
            <div class="content-logo">
                <div class="logo"></div>
            </div>
            
            <p>
                {{ lang[$store.getters.language].intro.textOne }}
            </p>
            <p>
                {{ lang[$store.getters.language].intro.textTwo }}
            </p>
            <div class="text-center">
                <button type="button" class="btn btn-brain-blue" @click="nextComponent">{{ lang[$store.getters.language].buttons.start }}</button>
            </div>
        </div>
       
        <div class="box-container-right text-center"></div>
    </div>
</template>

<style scoped>
</style>
  