<script>
import { language } from '../helper/lang'
export default {
  name: 'BrainFormOne',
  data () {
    return {
      lang: language,
      tumorSize: {
        value: '',
        isError: false,
        message: ''
      },
    }
  },
  updated() {
    this.tumorSize.message = this.lang[this.$store.getters.language].tumor.questionThree.error;
  },
  mounted() {
    let data = this.$store.getters.data;
    if (data.tumorSize) {
      this.tumorSize.value = data.tumorSize;
    }
  },
  methods: {
    prevComponent() {
      this.$store.dispatch('componentFormOne', true);
      this.$store.dispatch('componentFormTwo', false);
    },
    async nextComponent() {
      
      let invalid = false;

      if (this.tumorSize.value == '') {
        this.tumorSize.isError = true;
        invalid = true;
      }

      if (!invalid) {

        let data = await this.$store.getters.data,
            dataCalculated = await this.$store.getters.dataCalculated;
        
            data.tumorSize = this.tumorSize.value;

        if (this.tumorSize.value == '<=2') {
          dataCalculated.tumorSize = 0;
        } else if (this.tumorSize.value == '>2<=5') {
          dataCalculated.tumorSize = 0.539;
        } else if (this.tumorSize.value == '>5') {
          dataCalculated.tumorSize = 1.251;
        }
        
        await this.$store.dispatch('data', data);
        await this.$store.dispatch('dataCalculated', dataCalculated);
        await this.$store.dispatch('componentFormThree', true);
        await this.$store.dispatch('componentFormTwo', false);
      }
    },
  }
}

</script>

<template>
  <img src="../assets/img/Form.png" alt="Structure Brain" class="banner-form">
  <img src="../assets/img/logo.png" alt="Logo Brain" class="logo-form">
  <div class="box-container-form">
    <div class="content-title text-lg-start text-md-start text-center">
      <div class="title">{{ lang[$store.getters.language].tumor.title }}</div>
    </div>
    <div class="wrapper-form">
      <div class="form-group">
        <label class="col-form-label-lg">
          3.- {{ lang[$store.getters.language].tumor.questionThree.text }}
        </label>
        <div class="custom-radio" :class="{ 'is-invalid' : tumorSize.isError }">
          <input type="radio" id="option1" name="options" value="<=2" v-model="tumorSize.value">
          <label for="option1">{{ lang[$store.getters.language].tumor.questionThree.optionOne }}</label>
        </div>
        <div class="custom-radio">
          <input type="radio" id="option2" name="options" value=">2<=5" v-model="tumorSize.value">
          <label for="option2">{{ lang[$store.getters.language].tumor.questionThree.optionTwo }}</label>
        </div>
        <div class="custom-radio">
          <input type="radio" id="option3" name="options" value=">5" v-model="tumorSize.value">
          <label for="option3">{{ lang[$store.getters.language].tumor.questionThree.optionThree }}</label>
        </div>
        <div v-if="tumorSize.isError" class="invalid-feedback">
          {{ tumorSize.message }}
        </div>
      </div>
    </div>
    <div class="form-group text-md-start text-lg-start text-center">
      <button @click="prevComponent" class="btn-brain btn-brain-blue-prev">{{ lang[$store.getters.language].buttons.back }}</button>
      <button @click="nextComponent" class="btn-brain mx-auto btn-brain-blue-next">{{ lang[$store.getters.language].buttons.next }}</button>
    </div>
  </div>
</template>

<style scoped>
</style>
