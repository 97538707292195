export const language = {
    es: {
        intro: {
            textOne: 'Esta calculadora proporciona una estimación del riesgo de desarrollar metástasis cerebrales en mujeres con cáncer de mama confirmado patológicamente y debe ser utilizada con precaución',
            textTwo: 'Todas las variables clínicas ingresadas deberían haber sido medidas al momento del diagnóstico y las variables patológicas en el primer examen patológico.'
        },
        age: {
            title: 'Edad',
            questionOne: {
                text: 'Por favor, proporcione la fecha de nacimiento:',
                error: 'Por favor, selecciona una fecha'
            },
            questionTwo: {
                text: 'Por favor, proporcione la fecha del diagnóstico de cáncer de mama:',
                error: 'Por favor, selecciona una fecha'
            },
        },
        tumor: {
            title: 'Tamaño del Tumor',
            questionThree: {
                text: 'Por favor, seleccione el tamaño clínico mayor del tumor (diámetro clínico):',
                error: 'Por favor, selecciona una opción',
                optionOne: 'Menor o igual a 2cm',
                optionTwo: 'Mayor a 2 cm hasta 5 cm',
                optionThree: 'Mayor a 5 cm',
            },
        },
        adenopathy: {
            title: 'Adenopatía Axilar',
            questionFour: {
                text: 'Por favor, indique si la paciente tenía alguna adenopatía axilar al momento del diagnóstico de cáncer:',
                error: 'Por favor, selecciona una opción',
                optionOne: 'Si',
                optionTwo: 'No',
            },
        },
        clinical: {
            title: 'Estadio Clínico Anatómico',
            questionFive: {
                text: 'De acuerdo con la tabla, seleccione la etapa clínica al momento del diagnóstico de cáncer:',
                error: 'Por favor, selecciona una opción',
                optionOne: 'Etapa clínica I',
                optionTwo: 'Etapa clínica II',
                optionThree: 'Etapa clínica III',
                optionFour: 'Etapa clínica IV',
            },
        },
        estrogen: {
            title: 'Receptor de Estrógeno',
            questionSix: {
                text: 'La expresión del receptor de estrógeno fue:',
                error: 'Por favor, selecciona una opción',
                optionOne: 'Positivo (+)',
                optionTwo: 'Negativo (-)',
            },
        },
        ki67: {
            title: 'Ki-67',
            questionSeven: {
                text: 'La expresión de Ki-67 fue:',
                error: 'Por favor, selecciona una opción',
                optionOne: 'Menor a 10%',
                optionTwo: 'De 10% a 19%',
                optionThree: 'De 20% a 40%',
                optionFour: 'Mayor a 40%',
            },
        },
        sbr: {
            title: 'SBR',
            questionEight: {
                text: 'El puntaje Scarf-Bloom-Richardson se informó como:',
                error: 'Por favor, selecciona una opción',
                optionOne: 'SBR I = Puntuaciones de 3 a 5',
                optionTwo: 'SBR II = Puntuaciones de 6 o 7',
                optionThree: 'SBR III = Puntuaciones de 8 o 9',
            },
        },
        end: {
            title: 'Resultado',
            prevalence: 'Prevalencia aproximada',
            results: {
                low: 'Riesgo bajo de metástasis cerebrales',
                medium: 'Riesgo medio de metástasis cerebrales',
                high: 'Riesgo elevado de metástasis cerebrales',
                veryHigh: 'Riesgo muy elevado de metástasis cerebrales',
            }
        },
        buttons: {
            start: 'Empezar',
            back: 'Anterior',
            next: 'Siguiente',
            restart: 'Reiniciar Diagnóstico'
        }
    },
    en: {
        intro: {
            textOne: 'This calculator estimates the risk of brain metastases in women with breast cancer and should be used cautiously.',
            textTwo: 'All variables entered in the calculator should have been acquired at breast cancer diagnosis during the first clinical exam and first pathologic analysis.',
            button: 'Start'
        },
        age: {
            title: 'Age',
            questionOne: {
                text: 'Please provide the date of birth:',
                error: 'Please select a date'
            },
            questionTwo: {
                text: 'Please provide the date of breast cancer diagnosis:',
                error: 'Please select a date'
            },
        },
        tumor: {
            title: 'Tumor Size',
            questionThree: {
                text: 'Please select the largest clinical size of the tumor (clinical diameter):',
                error: 'Please select an option',
                optionOne: 'Less or equal to 2 cm',
                optionTwo: 'More than 2 cm until 5 cm',
                optionThree: 'More than 5 cm',
            },
        },
        adenopathy: {
            title: 'Axillary adenopathy',
            questionFour: {
                text: 'Please select if the patient had axillary adenopathy at the time of diagnosis:',
                error: 'Please select an option',
                optionOne: 'Yes',
                optionTwo: 'No',
            },
        },
        clinical: {
            title: 'Clinical Anatomical Stage',
            questionFive: {
                text: 'According to the following table, select the clinical stage at cancer diagnosis:',
                error: 'Please select an option',
                optionOne: 'Clinical Stage I',
                optionTwo: 'Clinical Stage II',
                optionThree: 'Clinical Stage III',
                optionFour: 'Clinical Stage IV',
            },
        },
        estrogen: {
            title: 'Estrogen Receptor',
            questionSix: {
                text: 'Estrogen receptor expression was:',
                error: 'Please select an option',
                optionOne: 'Positive (+)',
                optionTwo: 'Negative (-)',
            },
        },
        ki67: {
            title: 'Ki-67',
            questionSeven: {
                text: 'Ki67 expression was:',
                error: 'Please select an option',
                optionOne: 'Less than 10%',
                optionTwo: 'From 10% to 19%',
                optionThree: '20% to 40%',
                optionFour: 'More tan 40%',
            },
        },
        sbr: {
            title: 'SBR',
            questionEight: {
                text: 'The Scarff-Bloom-Richardson score was:',
                error: 'Please select an option',
                optionOne: 'SBR I = Score 3 to 5',
                optionTwo: 'SBR II = Score 6 or 7',
                optionThree: 'SBR III = Score 8 or 9',
            },
        },
        end: {
            title: 'Result:',
            prevalence: 'Approximate prevalence',
            results: {
                low: 'Low risk of brain metastases',
                medium: 'Medium risk of brain metastases',
                high: 'High risk of brain metastases',
                veryHigh: 'Very high risk of brain metastases',
            }
        },
        buttons: {
            start: 'Start',
            back: 'Previous',
            next: 'Next',
            restart: 'Reset Diagnostics'
        }
    }
}