<script>
import { language } from '../helper/lang'
export default {
  name: 'BrainFormFive',
  data () {
    return {
      lang: language,
      estrogenReceptor: {
        value: '',
        isError: false,
        message: ''
      },
    }
  },
  updated() {
    this.estrogenReceptor.message = this.lang[this.$store.getters.language].estrogen.questionSix.error;
  },
  mounted() {
    let data = this.$store.getters.data;
    if (data.estrogenReceptor) {
      this.estrogenReceptor.value = data.estrogenReceptor;
    }
  },
  methods: {
    prevComponent() {
      this.$store.dispatch('componentFormFour', true);
      this.$store.dispatch('componentFormFive', false);
    },
    async nextComponent() {

      let invalid = false;

      if (this.estrogenReceptor.value == '') {
        this.estrogenReceptor.isError = true;
        invalid = true;
      }

      if (!invalid) {

        let data = await this.$store.getters.data,
            dataCalculated = this.$store.getters.dataCalculated;

        data.estrogenReceptor = this.estrogenReceptor.value; 
        dataCalculated.estrogenReceptor = this.estrogenReceptor.value == 'positive' ? 0 : 0.64; 
        
        await this.$store.dispatch('data', data);
        await this.$store.dispatch('dataCalculated', dataCalculated);
        await this.$store.dispatch('componentFormSix', true);
        await this.$store.dispatch('componentFormFive', false);
      }
    }
  }
}

</script>

<template>
    <img src="../assets/img/Form.png" alt="Structure Brain" class="banner-form">
    <img src="../assets/img/logo.png" alt="Logo Brain" class="logo-form">
    <div class="box-container-form">
      <div class="content-title text-lg-start text-md-start text-center">
        <div class="title">{{ lang[$store.getters.language].estrogen.title }}</div>
      </div>
      <div class="wrapper-form">
        <div class="form-group">
          <label class="col-form-label-lg">
            6.- {{ lang[$store.getters.language].estrogen.questionSix.text }}
          </label>
          <div class="custom-radio" :class="{ 'is-invalid' : estrogenReceptor.isError }">
            <input type="radio" id="option10" name="estrogenReceptor" value="positive" v-model="estrogenReceptor.value">
            <label for="option10">{{ lang[$store.getters.language].estrogen.questionSix.optionOne }}</label>
          </div>
          <div class="custom-radio">
            <input type="radio" id="option12" name="estrogenReceptor" value="negative" v-model="estrogenReceptor.value">
            <label for="option12">{{ lang[$store.getters.language].estrogen.questionSix.optionTwo }}</label>
          </div>
          <div v-if="estrogenReceptor.isError" class="invalid-feedback">
            {{ estrogenReceptor.message }}
          </div>
        </div>
      </div>
      <div class="form-group text-md-start text-lg-start text-center">
        <button @click="prevComponent" class="btn-brain btn-brain-blue-prev">{{ lang[$store.getters.language].buttons.back }}</button>
        <button @click="nextComponent" class="btn-brain mx-auto btn-brain-blue-next">{{ lang[$store.getters.language].buttons.next }}</button>
      </div>
    </div>
</template>

<style scoped>
    
</style>
