<script>

  import BrainFormOne from './BrainFormOne.vue';
  import BrainFormTwo from './BrainFormTwo.vue';
  import BrainFormThree from './BrainFormThree.vue';
  import BrainFormFour from './BrainFormFour.vue';
  import BrainFormFive from './BrainFormFive.vue';
  import BrainFormSix from './BrainFormSix.vue';
  import BrainFormSeven from './BrainFormSeven.vue';
  import BrainResult from './BrainResult.vue';

  export default {
    name: 'BrainContainerForm',
    components: {
      BrainFormOne,
      BrainFormTwo,
      BrainFormThree,
      BrainFormFour,
      BrainFormFive,
      BrainFormSix,
      BrainFormSeven,
      BrainResult
    }
  }
</script>

<template>
  <div class="container-form">
    <div class="img-form"></div>
    <div class="logo-form"></div>
    <div class="box-form">
      <BrainFormOne v-if="$store.getters.componentFormOne" />
      <BrainFormTwo v-if="$store.getters.componentFormTwo" />
      <BrainFormThree v-if="$store.getters.componentFormThree" />
      <BrainFormFour v-if="$store.getters.componentFormFour" />
      <BrainFormFive v-if="$store.getters.componentFormFive" />
      <BrainFormSix v-if="$store.getters.componentFormSix" />
      <BrainFormSeven v-if="$store.getters.componentFormSeven" />
      <BrainResult v-if="$store.getters.componentResult" />
    </div>
  </div>
</template>

<style scoped>
    
</style>
