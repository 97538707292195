<script>
import { language } from '../helper/lang'
export default {
  name: 'BrainFormOne',
  data () {
    return {
      lang: language,
      birthday: {
        value: '',
        isError: false,
        message: ''
      },
      dateDiagnostic: {
        value: '',
        isError: false,
        message: ''
      },
    }
  },
  updated() {
    this.birthday.message = this.lang[this.$store.getters.language].age.questionOne.error;
    this.dateDiagnostic.message = this.lang[this.$store.getters.language].age.questionTwo.error;
  },
  mounted() {
    let data = this.$store.getters.data;
    if (data.birthday || data.dateDiagnostic) {
      this.birthday.value = data.birthday;
      this.dateDiagnostic.value = data.dateDiagnostic;
    }
  },
  methods: {
    prevComponent() {

      this.$store.dispatch('componentIntroduction', true);
      this.$store.dispatch('componentFormOne', false);
    },
    async nextComponent() {
      
      let invalid = false;

      if (this.birthday.value == '') {
        this.birthday.isError = true;
        invalid = true;
      }

      if (this.dateDiagnostic.value == '') {
        this.dateDiagnostic.isError = true;
        invalid = true;
      }

      if (!invalid) {

        let data = await this.$store.getters.data,
            dataCalculated = await this.$store.getters.dataCalculated,
            birthday = new Date(this.birthday.value),
            dateDiagnostic = new Date(this.dateDiagnostic.value);
        
        data.birthday = this.birthday.value;
        data.dateDiagnostic = this.dateDiagnostic.value;
        dataCalculated.date = this.restDate(birthday, dateDiagnostic) * -0.018;
        
        await this.$store.dispatch('data', data);
        await this.$store.dispatch('dataCalculated', dataCalculated);
        await this.$store.dispatch('componentFormTwo', true);
        await this.$store.dispatch('componentFormOne', false);
      }
    },
    restDate (date1, date2) {
      const yearInMilliseconds = 31536000000,
            differenceMilliseconds = Math.abs(date2 - date1),
            differenceYears = differenceMilliseconds / yearInMilliseconds,
            result = parseFloat(differenceYears.toFixed(2));
      return result;
    }
  }
}

</script>

<template>
  <img src="../assets/img/Form.png" alt="Logo Brain" class="banner-form">
  <img src="../assets/img/logo.png" alt="Structure Brain" class="logo-form">
  <div class="box-container-form">
    <div class="content-title text-lg-start text-md-start text-center">
      <div class="title">{{ lang[$store.getters.language].age.title }}</div>
    </div>
    <div class="wrapper-form">
      <div class="form-group">
        <label class="col-form-label-lg">
          1.- {{ lang[$store.getters.language].age.questionOne.text }}
        </label>
        <input 
          type="date" 
          class="form-control form-control-lg" 
          :class="{ 'is-invalid' : birthday.isError }"
          v-model="birthday.value">
        <div v-if="birthday.isError" class="invalid-feedback">
          {{ birthday.message }}
        </div>
      </div>
      <div class="form-group">
        <label class="col-form-label-lg">
          2.- {{ lang[$store.getters.language].age.questionTwo.text }}
        </label>
        <input 
          type="date" 
          lang="en-EU"
          class="form-control form-control-lg" 
          :class="{ 'is-invalid' : dateDiagnostic.isError }"
          v-model="dateDiagnostic.value">
        <div v-if="dateDiagnostic.isError" class="invalid-feedback">
          {{ dateDiagnostic.message }}
        </div>
      </div>
    </div>
    <div class="form-group text-md-start text-lg-start text-center">
      <button @click="prevComponent" class="btn-brain btn-brain-blue-prev">{{ lang[$store.getters.language].buttons.back }}</button>
      <button @click="nextComponent" class="btn-brain mx-auto btn-brain-blue-next">{{ lang[$store.getters.language].buttons.next }}</button>
    </div>
  </div>
</template>

<style scoped>
  .wrapper-form .form-group {
    margin-bottom: 5rem;
  }
</style>
