<script>
import { language } from '../helper/lang'
export default {
  name: 'BrainFormSeven',
  data () {
    return {
      lang: language,
      sbr: {
        value: '',
        isError: false,
        message: 'Por favor, selecciona una opción'
      },
    }
  },
  updated() {
    this.sbr.message = this.lang[this.$store.getters.language].sbr.questionEight.error;
  },
  mounted() {
    let data = this.$store.getters.data;
    if (data.sbr) {
      this.sbr.value = data.sbr;
    }
  },
  methods: {
    prevComponent() {
      this.$store.dispatch('componentFormSix', true);
      this.$store.dispatch('componentFormSeven', false);
    },
    async nextComponent() {

      let invalid = false;

      if (this.sbr.value == '') {
        this.sbr.isError = true;
        invalid = true;
      }

      if (!invalid) {

        let data = await this.$store.getters.data,
            dataCalculated = this.$store.getters.dataCalculated;

        data.sbr = this.sbr.value; 
        dataCalculated.sbr = 0; 
        if (this.sbr.value == '6-7') {
          dataCalculated.sbr = -0.317;
        } else if (this.sbr.value == '8-9') { 
          dataCalculated.sbr = 0.044;
        }
        
        await this.$store.dispatch('data', data);
        await this.$store.dispatch('dataCalculated', dataCalculated);
        await this.$store.dispatch('result');
        await this.$store.dispatch('componentResult', true);
        await this.$store.dispatch('componentFormSeven', false);
      }
    }
  }
}

</script>

<template>
  <img src="../assets/img/Form.png" alt="Structure Brain" class="banner-form">
  <img src="../assets/img/logo.png" alt="Logo Brain" class="logo-form">
  <div class="box-container-form">
    <div class="content-title text-lg-start text-md-start text-center">
      <div class="title">{{ lang[$store.getters.language].sbr.title }}</div>
    </div>
    <div class="wrapper-form">
      <div class="form-group">
        <label class="col-form-label-lg">
          8.- {{ lang[$store.getters.language].sbr.questionEight.text }}
        </label>
        <div class="custom-radio" :class="{ 'is-invalid' : sbr.isError }">
          <input type="radio" id="option15" name="sbr" value="3-5" v-model="sbr.value">
          <label for="option15">{{ lang[$store.getters.language].sbr.questionEight.optionOne }}</label>
        </div>
        <div class="custom-radio">
          <input type="radio" id="option16" name="sbr" value="6-7" v-model="sbr.value">
          <label for="option16">{{ lang[$store.getters.language].sbr.questionEight.optionTwo }}</label>
        </div>
        <div class="custom-radio">
          <input type="radio" id="option17" name="sbr" value="8-9" v-model="sbr.value">
          <label for="option17">{{ lang[$store.getters.language].sbr.questionEight.optionThree }}</label>
        </div>
        <div v-if="sbr.isError" class="invalid-feedback">
          {{ sbr.message }}
        </div>
      </div>
    </div>
    <div class="form-group text-md-start text-lg-start text-center">
      <button @click="prevComponent" class="btn-brain btn-brain-blue-prev">{{ lang[$store.getters.language].buttons.back }}</button>
      <button @click="nextComponent" class="btn-brain mx-auto btn-brain-blue-next">{{ lang[$store.getters.language].buttons.next }}</button>
    </div>
  </div>
</template>

<style scoped>
    
</style>
