<script>
    export default {
        name: 'BrainLanguage',
        methods: {
            nextComponent(lang) {
                this.$store.dispatch('language', lang);
                this.$store.dispatch('componentIntroduction', true);
            },
        },
    }
</script>

<template>
<div class="box-container">
    <div class="box-container-info-box">
        <div class="content-logo">
            <div class="logo"></div>
        </div>
        <div class="wrapper-buttons">
            <div class="content-buttons">
                <button class="button-menu" @click="nextComponent('es')">
                    <i class="mdi mdi-certificate"></i>
                    <span class="button-title">Español</span>
                </button>
                <button class="button-menu" @click="nextComponent('en')">
                    <i class="mdi mdi-school"></i>
                    <span class="button-title">English</span>
                </button>
            </div>
        </div>
    </div>
   
    <div class="box-container-right text-center"></div>
</div>
</template>

<style scoped>

.wrapper-buttons {
    width: 100%;
    height: 45vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(80px, auto);
    grid-auto-columns: minmax(80px, auto);
    grid-gap: 20px;
    width: 50%;
}

@media (max-width: 768px) {
    .content-buttons {
        width: 70%;
    }   

    .button-menu i {
        font-size: 40px;
    }
}

@media (max-width: 650px) {
    .content-buttons {
        width: 80%;
        grid-auto-rows: minmax(180px, auto);
    }
}

@media (max-width: 500px) {
    .content-buttons {
        width: 90%;
        grid-auto-rows: minmax(160px, auto);
    }
}

.button-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: transparent;
    color: rgb(35,90,134);
    border: 1px dashed rgba(35,90,134, 1);
    border-radius: .5em;
}

.button-menu:hover {
    background: rgb(35,90,134);
    color: #fff;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.button-menu i {
    font-size: 50px;
}

</style>
