<script>
import { language } from '../helper/lang'
export default {
  name: 'BrainFormThree',
  data () {
    return {
      lang: language,
      axillaryAdenopathy: {
        value: '',
        isError: false,
        message: ''
      },
    }
  },
  updated() {
    this.axillaryAdenopathy.message = this.lang[this.$store.getters.language].adenopathy.questionFour.error;
  },
  mounted() {
    let data = this.$store.getters.data;
    if (data.axillaryAdenopathy) {
      this.axillaryAdenopathy.value = data.axillaryAdenopathy;
    }
  },
  methods: {
    prevComponent() {
      this.$store.dispatch('componentFormTwo', true);
      this.$store.dispatch('componentFormThree', false);
    },
    async nextComponent() {
      
      let invalid = false;

      if (this.axillaryAdenopathy.value == '') {
        this.axillaryAdenopathy.isError = true;
        invalid = true;
      }

      if (!invalid) {

        let data = await this.$store.getters.data,
            dataCalculated = this.$store.getters.dataCalculated;
        
            data.axillaryAdenopathy = this.axillaryAdenopathy.value; 
            dataCalculated.axillaryAdenopathy = (this.axillaryAdenopathy.value == 'si') ? 0.623 : 0; 
        
        await this.$store.dispatch('data', data);
        await this.$store.dispatch('dataCalculated', dataCalculated);
        await this.$store.dispatch('componentFormFour', true);
        await this.$store.dispatch('componentFormThree', false);
      }
    },
  }
}

</script>

<template>
  <img src="../assets/img/Form.png" alt="Structure Brain" class="banner-form">
  <img src="../assets/img/logo.png" alt="Logo Brain" class="logo-form">
  <div class="box-container-form">
    <div class="content-title text-lg-start text-md-start text-center">
      <div class="title">{{ lang[$store.getters.language].adenopathy.title }}</div>
    </div>
    <div class="wrapper-form">
      <div class="form-group">
        <label class="col-form-label-lg">
          4.- {{ lang[$store.getters.language].adenopathy.questionFour.text }}
        </label>
        <div class="custom-radio" :class="{ 'is-invalid' : axillaryAdenopathy.isError }">
          <input type="radio" id="option4" name="axillaryAdenopathy" value="si" v-model="axillaryAdenopathy.value">
          <label for="option4">{{ lang[$store.getters.language].adenopathy.questionFour.optionOne }}</label>
        </div>
        <div class="custom-radio">
          <input type="radio" id="option5" name="axillaryAdenopathy" value="no" v-model="axillaryAdenopathy.value">
          <label for="option5">{{ lang[$store.getters.language].adenopathy.questionFour.optionTwo }}</label>
        </div>
        <div v-if="axillaryAdenopathy.isError" class="invalid-feedback">
          {{ axillaryAdenopathy.message }}
        </div>
      </div>
    </div>
    <div class="form-group text-md-start text-lg-start text-center">
      <button @click="prevComponent" class="btn-brain btn-brain-blue-prev">{{ lang[$store.getters.language].buttons.back }}</button>
      <button @click="nextComponent" class="btn-brain mx-auto btn-brain-blue-next">{{ lang[$store.getters.language].buttons.next }}</button>
    </div>
  </div>
</template>

<style scoped>
    
</style>
