<script>
  import { language } from '../helper/lang'
  export default {
    name: 'BrainResult',
    data () {
      return {
        result: [],
        hidden: false,
        lang: language,
      }
    },
    mounted() {
      this.result = this.$store.getters.result;
      setTimeout(() => {
        this.hidden = !this.hidden;
      },4000)
    },
    methods: {
      reset () {
        window.location.assign('/');
      }
    }
  }
</script>

<template>
    <div class="banner-result d-flex align-items-center">
      <div class="content-result">
        <div class="row">
          <div class="col-md-12">
            <p class="title-result">{{ lang[$store.getters.language].end.title }}</p>
          </div>
          <div class="col-md-12">
            <p class="result">{{ result.valor }}</p>
          </div>
          <div class="col-md-12">
            <p class="subtitle-result">{{ lang[$store.getters.language].end.prevalence }}</p>
          </div>
          <div class="col-md-12">
            <p class="result">{{ result.prevalence }}</p>
          </div>
        </div>  

        <span class="d-flex justify-content-center text-white mt-3" style="!important; font-size: 1.35rem;">
          Cacho-Díaz B&nbsp;<i>et al.</i>&nbsp;Cancer Epidemiology. 2023 Oct; 86: 102448
        </span>

        <button @click="reset" 
          class="btn-brain btn-brain-reset animate__animated animate__fadeInUp" 
          :class="{ 'd-none': !hidden }">
          {{ lang[$store.getters.language].buttons.restart }}
        </button>

        <!-- <div class="table-comparative table-responsive">
          <table class="table table-bordered table-sm">
            <thead class="thead-white">
              <tr>
                <th>VALOR</th>
                <th>INTERPRETACIÓN</th>
                <th>PREVALENCIA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>VALOR ≤ -3.4605</td>
                <td>Posibilidad estándar de metástasis cerebral</td>
                <td>1.20%</td>
              </tr>
              <tr>
                <td>VALOR -3.4604 a -2.7559</td>
                <td>Posibilidad moderada de metástasis cerebral</td>
                <td>4.80%</td>
              </tr>
              <tr>
                <td>VALOR -2.7558 a -1.7321</td>
                <td>Posibilidad alta de metástasis cerebral</td>
                <td>9.50%</td>
              </tr>
              <tr>
                <td>VALOR ≥ -1.7320</td>
                <td>Posibilidad muy alta de metástasis cerebral</td>
                <td>22.60%</td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </div>
</template>

<style scoped>

</style>
