<template>
  <BrainContainer />
</template>

<script>
  import BrainContainer from './components/BrainContainer.vue'

  export default {
    name: 'App',
    components: {
      BrainContainer
    }
  }
</script>

<style>
</style>
