<script>
  import BrainIntro from  './BrainIntro.vue'
  import BrainContainerForm from  './BrainContainerForm.vue'
  import BrainButtonLanguage from  './BrainButtonLanguage.vue'
  export default {
    name: 'BrainContainer',
    components: {
      BrainIntro,
      BrainContainerForm,
      BrainButtonLanguage
    }
  }
</script>

<template>
  <BrainButtonLanguage v-if="!$store.getters.language" />
  <BrainIntro v-if="$store.getters.componentIntroduction" />
  <BrainContainerForm v-if="!$store.getters.componentIntroduction" />
</template>

<style scoped>
  
</style>
