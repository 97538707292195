import { createStore } from 'vuex'
import { language as lang } from '@/helper/lang';

export default createStore({
  state: {
    language: false,

    componentIntroduction: false,
    componentFormOne: false,
    componentFormTwo: false,
    componentFormThree: false,
    componentFormFour: false,
    componentFormFive: false,
    componentFormSix: false,
    componentFormSeven: false,
    componentResult: false,
    data: {
      birthday: '',
      dateDiagnostic: '',
      tumorSize: 0,
      axillaryAdenopathy: 0,
      clinicalStage: 0,
      estrogenReceptor: 0,
      ki67: 0,
      sbr: 0,
    },
    dataCalculated: {
      date: 0,
      tumorSize: 0,
      axillaryAdenopathy: 0,
      clinicalStage: 0,
      estrogenReceptor: 0,
      ki67: 0,
      sbr: 0,
    },
    dataStand: {
      lowRisk: '1.20%',
      mediumRick: '4.80%',
      highRisk: '9.50%',
      veryHighRisk: '22.60%'
    },
    dataValorDefault: {
      valorOne: -3.4605,
      valorTwo: -3.4604,
      valorThree: -2.7558,
      valorFour: -2.7559,
      valorFive: -1.7321,
      valorSix: -1.7320,
    },
    result: {
      valor: '',
      prevalence: 0
    },
  },
  getters: {
    language (state) {
      return state.language;
    },
    componentIntroduction (state) {
      return state.componentIntroduction;
    },
    componentFormOne (state) {
      return state.componentFormOne;
    },
    componentFormTwo (state) {
      return state.componentFormTwo;
    },
    componentFormThree (state) {
      return state.componentFormThree;
    },
    componentFormFour (state) {
      return state.componentFormFour;
    },
    componentFormFive (state) {
      return state.componentFormFive;
    },
    componentFormSix (state) {
      return state.componentFormSix;
    },
    componentFormSeven (state) {
      return state.componentFormSeven;
    },
    componentResult (state) {
      return state.componentResult;
    },
    data (state)  {
      return state.data;
    },
    dataCalculated (state)  {
      return state.dataCalculated;
    },
    result (state)  {
      return state.result;
    }
  },
  mutations: {
    language (state, lang) {
      return state.language = lang;
    },
    componentIntroduction (state, status) {
      return state.componentIntroduction = status;
    },
    componentFormOne (state, status) {
      return state.componentFormOne = status;
    },
    componentFormTwo (state, status) {
      return state.componentFormTwo = status;
    },
    componentFormThree (state, status) {
      return state.componentFormThree = status;
    },
    componentFormFour (state, status) {
      return state.componentFormFour = status;
    },
    componentFormFive (state, status) {
      return state.componentFormFive = status;
    },
    componentFormSix (state, status) {
      return state.componentFormSix = status;
    },
    componentFormSeven (state, status) {
      return state.componentFormSeven = status;
    },
    componentResult (state, status) {
      return state.componentResult = status;
    },
    data (state, data) {
      return state.data = data;
    },
    dataCalculated (state, dataCalculated) {
      return state.dataCalculated = dataCalculated;
    },
    result (state, result) {
      return state.result = result;
    }
  },
  actions: {
    language ({commit}, lang) {
      commit('language', lang);
    },
    componentIntroduction ({commit}, status) {
      commit('componentIntroduction', status);
    },
    componentFormOne ({commit}, status) {
      commit('componentFormOne', status);
    },
    componentFormTwo ({commit}, status) {
      commit('componentFormTwo', status);
    },
    componentFormThree ({commit}, status) {
      commit('componentFormThree', status);
    },
    componentFormFour ({commit}, status) {
      commit('componentFormFour', status);
    },
    componentFormFive ({commit}, status) {
      commit('componentFormFive', status);
    },
    componentFormSix ({commit}, status) {
      commit('componentFormSix', status);
    },
    componentFormSeven ({commit}, status) {
      commit('componentFormSeven', status);
    },
    componentResult ({commit}, status) {
      commit('componentResult', status);
    },
    data ({commit}, data) {
      commit('data', data);
    },
    dataCalculated ({commit}, dataCalculated) {
      commit('dataCalculated', dataCalculated);
    },
    result ({commit, state}) {

      let data = state.dataCalculated,
          equationResult = (-3.54 + data.date + data.tumorSize + data.clinicalStage + data.estrogenReceptor + data.ki67 + data.sbr).toFixed(4),
          prevalence = 0,
          valor = '';

      if (equationResult <= state.dataValorDefault.valorOne ) {
        prevalence = state.dataStand.lowRisk;
        valor = lang[state.language].end.results.low;
      } else if (equationResult >= state.dataValorDefault.valorTwo && equationResult <= state.dataValorDefault.valorFour) {
        prevalence = state.dataStand.mediumRick;
        valor = lang[state.language].end.results.medium;
      } else if (equationResult >= state.dataValorDefault.valorThree && equationResult <= state.dataValorDefault.valorFive) {
        prevalence = state.dataStand.highRisk;
        valor = lang[state.language].end.results.high;
      } else if (equationResult >= state.dataValorDefault.valorSix) {
        prevalence = state.dataStand.veryHighRisk;
        valor = lang[state.language].end.results.veryHigh;
      }

      commit('result', {
        valor: valor,
        prevalence: prevalence
      });
    }
  },
  modules: {
    
  }
})
