<script>
import { language } from '../helper/lang'
export default {
  name: 'BrainFormSix',
  data () {
    return {
      lang: language,
      ki67: {
        value: '',
        isError: false,
        message: ''
      },
    }
  },
  updated() {
    this.ki67.message = this.lang[this.$store.getters.language].ki67.questionSeven.error;
  },
  mounted() {
    let data = this.$store.getters.data;
    if (data.ki67) {
      this.ki67.value = data.ki67;
    }
  },
  methods: {
    prevComponent() {
      this.$store.dispatch('componentFormFive', true);
      this.$store.dispatch('componentFormSix', false);
    },
    async nextComponent() {

      let invalid = false;

      if (this.ki67.value == '') {
        this.ki67.isError = true;
        invalid = true;
      }

      if (!invalid) {

        let data = await this.$store.getters.data,
            dataCalculated = this.$store.getters.dataCalculated;

        data.ki67 = this.ki67.value; 
        dataCalculated.ki67 = 0; 
        if (this.ki67.value == '>=10<=19') {
          dataCalculated.ki67 = 0.633;
        } else if (this.ki67.value == '>=20<=40') { 
          dataCalculated.ki67 = 1.008;
        } else if (this.ki67.value == '>40') { 
          dataCalculated.ki67 = 0.625;
        }
        
        await this.$store.dispatch('data', data);
        await this.$store.dispatch('dataCalculated', dataCalculated);
        await this.$store.dispatch('componentFormSeven', true);
        await this.$store.dispatch('componentFormSix', false);
      }
    }
  }
}

</script>

<template>
  <img src="../assets/img/Form.png" alt="Structure Brain" class="banner-form">
  <img src="../assets/img/logo.png" alt="Logo Brain" class="logo-form">
  <div class="box-container-form">
    <div class="content-title text-lg-start text-md-start text-center">
      <div class="title">Ki-67</div>
    </div>
    <div class="wrapper-form">
      <div class="form-group">
        <label class="col-form-label-lg">
          7.- {{ lang[$store.getters.language].ki67.questionSeven.text }}
        </label>
        <div class="custom-radio" :class="{ 'is-invalid' : ki67.isError }">
          <input type="radio" id="option11" name="ki67" value="<10" v-model="ki67.value">
          <label for="option11">{{ lang[$store.getters.language].ki67.questionSeven.optionOne }}</label>
        </div>
        <div class="custom-radio">
          <input type="radio" id="option12" name="ki67" value=">=10<=19" v-model="ki67.value">
          <label for="option12">{{ lang[$store.getters.language].ki67.questionSeven.optionTwo }}</label>
        </div>
        <div class="custom-radio">
          <input type="radio" id="option13" name="ki67" value=">=20<=40" v-model="ki67.value">
          <label for="option13">{{ lang[$store.getters.language].ki67.questionSeven.optionThree }}</label>
        </div>
        <div class="custom-radio">
          <input type="radio" id="option14" name="ki67" value=">40" v-model="ki67.value">
          <label for="option14">{{ lang[$store.getters.language].ki67.questionSeven.optionFour }}</label>
        </div>
        <div v-if="ki67.isError" class="invalid-feedback">
          {{ ki67.message }}
        </div>
      </div>
    </div>
    <div class="form-group text-md-start text-lg-start text-center">
      <button @click="prevComponent" class="btn-brain btn-brain-blue-prev">{{ lang[$store.getters.language].buttons.back }}</button>
      <button @click="nextComponent" class="btn-brain mx-auto btn-brain-blue-next">{{ lang[$store.getters.language].buttons.next }}</button>
    </div>
  </div>
</template>

<style scoped>
    
</style>
